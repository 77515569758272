import { serialize } from 'object-to-formdata'
import { cookieRef } from '@layouts/stores/config'

export const useApi = () => {
	const requestDelayTime = 500
	const requestStatus = ref('idle')
	const locale = toValue(cookieRef('language', 'ru'))

	async function index(
		endpoint,
		{ defaultFilters = {}, defaultLimit = 10, defaultBranchId = null, defaultSort = null } = {}
	) {
		const data = ref([])
		const limit = ref(defaultLimit)
		const page = ref(1)
		const sort = ref(defaultSort)
		const filters = ref(defaultFilters)
		const total = ref()
		const meta = ref()
		const error = ref()
		const errors = ref()
		const isError = ref()
		const status = ref()
		const refreshTime = ref(currentTime())
		const branchId = ref(defaultBranchId ?? branch().id)

		const _index = async () => {
			const query = generateQuery(page, limit, sort, branchId, filters)

			const {
				response,
				error: apiError,
				status: apiStatus,
			} = await request({ endpoint, query })

			const responseValue = toValue(response)
			const errorParams = getErrorParams(apiError)

			if (!errorParams.statusCode) {
				data.value = getDataResponse(response)
				meta.value = responseValue?.meta ?? null
				total.value = responseValue?.meta?.total ?? null
			}

			error.value = errorParams
			errors.value = errorParams.errors
			isError.value = errorParams?.statusCode ? true : false
			status.value = toValue(apiStatus)
		}

		await _index()

		watch(
			[page, limit, sort, filters, refreshTime, branchId],
			async () => {
				_index()
			},
			{
				deep: true,
			}
		)

		return {
			data,
			total,
			limit,
			page,
			sort,
			filters,
			meta,
			status,
			error,
			errors,
			isError,
			refresh: () => setRefreshTime(refreshTime),
		}
	}

	function getDataResponse(response) {
		const responseValue = toValue(response)
		const dataValue = responseValue?.data ?? responseValue

		return typeof dataValue === 'object' && dataValue !== null ? dataValue : []
	}

	async function show(endpoint, id, { defaultQuery = {} }) {
		const data = ref()
		const error = ref()
		const errors = ref()
		const status = ref()
		const refreshTime = ref(currentTime())
		const endpointRequest = `${endpoint}/${id}`

		const _show = async () => {
			const {
				response,
				error: apiError,
				status: apiStatus,
			} = await request({ endpoint: endpointRequest, query: defaultQuery })

			const errorParams = getErrorParams(apiError)

			if (!errorParams.statusCode) {
				data.value = data.value = getDataResponse(response)
				error.value = toValue(apiError)
				status.value = toValue(apiStatus)
			}

			error.value = errorParams
			errors.value = errorParams.errors
			status.value = toValue(apiStatus)
		}

		await _show()

		watch(refreshTime, async () => {
			_show()
		})

		return {
			data,
			error,
			errors,
			status,
			refresh: () => setRefreshTime(refreshTime),
		}
	}

	async function createOrUpdate(endpoint, data, id = null) {
		if (id) {
			return await update(endpoint, id, data)
		} else {
			return await create(endpoint, data)
		}
	}

	async function create(endpoint, data) {
		const body = generateBody(data)

		const { response, error } = await request({
			endpoint,
			method: 'post',
			body,
			query: { locale },
		})

		const responseErrors = getErrorResponse(error)

		return Object.assign({ response }, responseErrors)
	}

	async function update(endpoint, id, data) {
		const endpointRequest = `${endpoint}/${id}`

		const body = generateBody(data)
		body.append('_method', 'put')

		const { response, error } = await request({
			endpoint: endpointRequest,
			method: 'post',
			body,
			query: { locale },
		})

		const responseErrors = getErrorResponse(error)

		return Object.assign({ response }, responseErrors)
	}

	async function destroy(endpoint, id, data = null) {
		const endpointRequest = `${endpoint}/${id}`

		const body = generateBody(data)
		body.append('_method', 'delete')

		const { response, error } = await request({
			endpoint: endpointRequest,
			method: 'post',
			body,
			query: { locale },
		})

		const responseErrors = getErrorResponse(error)

		const responseValue = toValue(response)

		return Object.assign(
			{
				data: ref(responseValue?.data ?? responseValue),
			},
			responseErrors
		)
	}

	function setRefreshTime(refreshTime) {
		const current = currentTime()

		if (current - refreshTime.value > requestDelayTime) {
			refreshTime.value = current
		}
	}

	function getErrorResponse(requestError) {
		const errorParams = getErrorParams(requestError)

		return {
			errors: ref(errorParams.errors),
			error: ref(errorParams),
			isError: ref(errorParams?.statusCode ? true : false),
			errorMessage: ref(errorParams?.statusMessage),
		}
	}

	function getErrorParams(error) {
		const errorValue = toValue(error)

		return {
			statusCode: errorValue?.statusCode ?? null,
			statusMessage: errorValue?.statusMessage ?? null,
			message: errorValue?.data?.message ?? null,
			errors: errorValue?.data?.errors ?? null,
		}
	}

	function generateQuery(page, limit, sort, branchId, filters = null) {
		return Object.assign(
			{
				page: toValue(page),
				limit: toValue(limit),
				sort: toValue(sort),
				branch_id: toValue(branchId),
				locale,
			},
			getFiltersQuery(filters)
		)
	}

	function getFiltersQuery(filters) {
		let result = {}
		const filtersValue = toValue(filters)

		if (filtersValue) {
			for (const key in filtersValue) {
				let value = filtersValue[key]

				if (value === null || value === undefined || value.length == 0) {
					continue
				} else {
					result[`filter[${key}]`] = value
				}
			}
		}

		return result
	}

	async function request({ endpoint, method = 'get', body = null, query = null }) {
		const client = useSanctumClient()

		const handler = () =>
			client(endpoint, {
				method,
				body,
				query,
			})

		requestStatus.value = 'sent'

		const { data: response, error } = await useAsyncData(endpoint, handler)

		requestStatus.value = 'completed'

		return {
			response,
			error,
		}
	}

	function generateBody(data) {
		return serialize(toValue(data), {
			indices: true,
		})
	}

	return {
		index,
		show,
		request,
		create,
		update,
		destroy,
		createOrUpdate,
		requestStatus,
	}
}
