export const currentTime = () => {
	return new Date().getTime()
}

export const dateTime = (value) => {
	return date(value)
}

export const dateDate = (value) => {
	return date(value, null, {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	})
}

export const weekdayIndex = (value) => {
	return ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'set'].indexOf(value)
}

export const date = (value, locale = null, options = null) => {
	if (!locale) {
		locale = 'ru-RU'
	}

	if (!options) {
		options = {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
		}
	}

	const dateObj = new Date(value)

	return dateObj.toLocaleDateString(locale, options)
}
